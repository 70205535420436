@import url('@scania/tegel/dist/tegel/tegel.css');

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; visibility: visible; overflow: auto; }

tds-accordion {
    --tds-accordion-border: 0;
}

tds-accordion .tds-accordion-item:focus,
tds-accordion .tds-accordion-item:visited:focus {
  outline: none;
  outline-offset: -2px;
}

.swal2-popup {
  font-size: 15px !important;
}

.revert-select select{
  appearance: revert !important;
  overflow: revert !important;
  text-align: start !important;
}

.tds-align-helper .text-field-helper {
  text-align: left;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

app-acessos-usuarios,
app-codigos,
app-marca-modelo,
app-notificacao,
app-retorno-precad,
app-solicitacao-envio,
app-renave {
  flex-grow: 1;
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2px); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

body[data-env]:before {
  background-color: #ddd;
  display: inline-block;
  padding: 52px 50px 2px;
  content: attr(data-env);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1170;
  -ms-transform: translateY(-50%) translateX(-50%) rotate(-45deg);
  -moz-transform: translateY(-50%) translateX(-50%) rotate(-45deg);
  -webkit-transform: translateY(-50%) translateX(-50%) rotate(-45deg);
  -o-transform: translateY(-50%) translateX(-50%) rotate(-45deg);
  transform: translateY(-50%) translateX(-50%) rotate(-45deg);
  text-transform: uppercase;
  font-size: 14px;
  border: 2px solid #fafafa;
}
body[data-env=LOCAL]:before,
body[data-env=DEV]:before {
  background-color: #94a596;
  color: #fff;
}
body[data-env=ACP]:before {
  background-color: #ceb888;
  color: #fff;
}
body[data-env=""]:before {
  display: none;
}